.logout-button {
    padding: 10px 20px;
    margin: 8px 8px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
  }
  