@import url('https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i&display=swap'); 
@font-face { 
  font-display:swap;
  font-family:Carbon;
  font-style:normal;
  font-weight:400;
  src:url('../public/fonts/carbon.woff2') format("woff2"),url('../public/fonts/carbon.woff') format("woff");
} 

  .body {  
    text-transform:uppercase;
    background:var(--bg);
    background-image:linear-gradient(to right,var(--gray-900) 1px,transparent 1px),linear-gradient(to bottom,var(--gray-900) 1px,transparent 1px);
    background-position: calc(50% - var(--grid-size)/2) calc(50% - var(--grid-size)/2);
    background-position: var(--grid-offset-h) var(--grid-offset-v);
    background-size: var(--grid-size) var(--grid-size);
    height: var(--100vh);
    overflow: hidden;
    width: 100vw;
    font-family:system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Arial,Apple Color Emoji,Segoe UI Emoji;
    line-height:1.15;
    color:hsla(0,0%,100%,.87);
    font-size:16px;
    font-weight:400;
  }  
.body { 
/* CSS Variables that may have been missed get put on .body */ 
    --gray-1000:  #000;  
    --gray-800:  #0b0b0b;  
    --gray-600:  #191919;  
    --gray-400:  #333;  
    --gray-200:  #696969;  
    --gray-100:  #b4b5b4;  
    --grid-offset-h: 1.7vw;
    --grid-offset-v: 0.2vw;
    --semi:  hsla(0,0%,4%,.8);  
    --gray-1000: #000; 
    --bg:  var(--gray-1000);  
    --gray-600: #191919; 
    --border-color:  var(--gray-600);  
    --gray-900: #161616; 
    --border-color-dark:  var(--gray-800);  
    --gray-400: #333; 
    --border-color-light:  var(--gray-400);  
    --size-2: 0.5rem; 
    --bg-blur:  blur(var(--size-2));  
    --border-color: var(--gray-600); 
    --border-box:  0 0 0 1px var(--border-color);  
    --border-color-dark: var(--gray-800); 
    --border-box-dark:  0 0 0 1px var(--border-color-dark);  
    --border:  1px solid var(--border-color);  
    --border-dark:  1px solid var(--border-color-dark);  
    --border-color-light: var(--gray-400); 
    --border-light:  1px solid var(--border-color-light);  
    --gray-100: #b4b5b4; 
    --border-white:  1px solid var(--gray-100);  
    --size-0:  0.125rem;  
    --size-1:  0.25rem;  
    --size-0: 0.125rem; 
    --size-1: 0.25rem; 
    --size-1_5:  calc(var(--size-0) + var(--size-1));  
    --size-2:  0.5rem;  
    --size-3:  0.75rem;  
    --size-4:  1rem;  
    --size-5:  1.5rem;  
    --size-6:  2rem;  
    --grid-size:  2.5rem;  
    --font-size-sm:  0.8rem;  
    --speed-fast:  0.15s;  
    --speed:  0.3s;  
    --speed-slow:  0.45s;  
    --grid-size:  3.75rem; 
} 

* { 
    border: 0 solid #e5e7eb; 
    box-sizing: border-box;
} 

* { 
    text-transform: uppercase;
} 

.body { 
    font-family: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Arial,Apple Color Emoji,Segoe UI Emoji; 
    margin: 0;
    padding-right:15px;
} 

.body { 
    font-family: inherit; 
    line-height: inherit;
} 


html { 
    -webkit-text-size-adjust: 100%; 
    line-height: 1.15;
    overflow-x:hidden;
} 

html { 
    font-family: ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; 
    line-height: 1.5;
} 

:root { 
    --gray-1000: #000; 
    --gray-800: #0b0b0b; 
    --gray-600: #191919; 
    --gray-400: #333; 
    --gray-200: #696969; 
    --gray-100: #b4b5b4; 
    --semi: hsla(0,0%,4%,.8); 
    --bg: var(--gray-1000); 
    --border-color: var(--gray-600); 
    --border-color-dark: var(--gray-800); 
    --border-color-light: var(--gray-400); 
    --bg-blur: blur(var(--size-2)); 
    --border-box: 0 0 0 1px var(--border-color); 
    --border-box-dark: 0 0 0 1px var(--border-color-dark); 
    --border: 1px solid var(--border-color); 
    --border-dark: 1px solid var(--border-color-dark); 
    --border-light: 1px solid var(--border-color-light); 
    --border-white: 1px solid var(--gray-100); 
    --size-0: 0.125rem; 
    --size-1: 0.25rem; 
    --size-1_5: calc(var(--size-0) + var(--size-1)); 
    --size-2: 0.5rem; 
    --size-3: 0.75rem; 
    --size-4: 1rem; 
    --size-5: 1.5rem; 
    --size-6: 2rem; 
    --grid-size: 2.5rem; 
    --font-size-sm: 0.8rem; 
    --speed-fast: 0.15s; 
    --speed: 0.3s; 
    --speed-slow: 0.45s; 
} 

@media (min-width: 45rem){ 
  :root { 
    --grid-size: 3.75rem;
  } 
}     

:root { 
    -moz-tab-size: 4; 
    -o-tab-size: 4; 
    tab-size: 4;
} 

:root { 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    -webkit-text-size-adjust: 100%; 
    scroll-behavior: smooth; 
    background-color: #000; 
    color: hsla(0,0%,100%,.87); 
    color-scheme: only dark; 
    font-family: Carbon,Space Mono,Inter,Avenir,Helvetica,Arial,sans-serif; 
    font-size: 16px; 
    font-synthesis: none; 
    font-weight: 400; 
    line-height: 24px; 
    text-rendering: optimizeLegibility;
} 

.inner[data-v-aaa30f88] { 
    display: flex; 
    flex-direction: column; 
    position: relative;
} 

*,:after,:before { 
    border: 0 solid #e5e7eb; 
    box-sizing: border-box;
} 

:backdrop,:root { 
    --gray-1000: #000; 
    --gray-800: #0b0b0b; 
    --gray-600: #191919; 
    --gray-400: #333; 
    --gray-200: #696969; 
    --gray-100: #b4b5b4; 
    --semi: hsla(0,0%,4%,.8); 
    --bg: var(--gray-1000); 
    --border-color: var(--gray-600); 
    --border-color-dark: var(--gray-800); 
    --border-color-light: var(--gray-400); 
    --bg-blur: blur(var(--size-2)); 
    --border-box: 0 0 0 1px var(--border-color); 
    --border-box-dark: 0 0 0 1px var(--border-color-dark); 
    --border: 1px solid var(--border-color); 
    --border-dark: 1px solid var(--border-color-dark); 
    --border-light: 1px solid var(--border-color-light); 
    --border-white: 1px solid var(--gray-100); 
    --size-0: 0.125rem; 
    --size-1: 0.25rem; 
    --size-1_5: calc(var(--size-0) + var(--size-1)); 
    --size-2: 0.5rem; 
    --size-3: 0.75rem; 
    --size-4: 1rem; 
    --size-5: 1.5rem; 
    --size-6: 2rem; 
    --grid-size: 2.5rem; 
    --font-size-sm: 0.8rem; 
    --speed-fast: 0.15s; 
    --speed: 0.3s; 
    --speed-slow: 0.45s; 
} 

@media (min-width: 45rem){ 
  :backdrop,:root { 
    --grid-size: 3.75rem;
  } 
}     

nav[data-v-b3d01395] { 
    display: flex; 
    font-size: var(--font-size-sm); 
    margin-bottom: var(--grid-size); 
    position: relative; 
    width: 100%; 
    z-index: 200;
} 

@media (min-width: 45rem){ 
  nav[data-v-b3d01395] { 
    font-size: var(--font-size-base);
  } 
}     

.page[data-v-aa7f54ab] { 
    align-items: center; 
    display: flex; 
    flex-direction: column; 
    height: 100%; 
    justify-content: space-between;
} 

.toggle {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

.toggle > button {
    flex-basis: 7%;
}

.spacer {
    flex-grow: 1;
}

.spacer2 {
    flex-grow: 1;
}

a { 
    color: inherit; 
    text-decoration: inherit;
} 

nav > a[data-v-b3d01395]:first-child  { 
    align-items: center; 
    display: flex; 
    flex-shrink: 0; 
    height: var(--grid-size); 
    justify-content: center; 
    margin-right: auto; 
    width: var(--grid-size);
} 

button { 
    font-family: inherit; 
    font-size: 100%; 
    line-height: 1.15; 
    margin: 0;
} 

button { 
    text-transform: none;
} 

button { 
    -webkit-appearance: button;
} 

button { 
    background-color: transparent; 
    background-image: none;
} 

button { 
    cursor: pointer;
} 

button { 
    color: inherit; 
    line-height: inherit; 
    padding: 0;
} 

.button,button { 
    background: var(--bg); 
    box-shadow: var(--border-box); 
    justify-content: center; 
    outline: none; 
    text-align: center; 
    text-transform: uppercase;
} 

.button,button { 
    border-color: transparent!important;
} 

.button[data-v-b857464c],button[data-v-b857464c],input[data-v-b857464c] { 
    align-items: center; 
    background: var(--bg); 
    box-shadow: var(--border-box); 
    display: flex; 
    height: var(--grid-size); 
    justify-content: center; 
    padding: var(--size-2) var(--size-3); 
    text-align: center; 
    text-transform: uppercase; 
    transition: background var(--speed),color var(--speed),box-shadow var(--speed); 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    user-select: none;
} 

input[data-v-b857464c] {
  font-family: Carbon;
  font-size: 1rem;
  text-align: left;
}


nav[data-v-b3d01395] .button  { 
    min-width: calc(var(--grid-size)*2);
} 

nav .menu-toggle[data-v-b3d01395]  { 
    width: var(--grid-size);
} 

@media (min-width: 45rem){ 
  nav .menu-toggle[data-v-b3d01395]  { 
    display: none;
  } 
}     

.button.icon[data-v-b857464c],button.icon[data-v-b857464c] { 
    padding: var(--size-3);
} 

.button:hover:enabled,button:hover:enabled { 
    background: var(--gray-800)!important; 
    border-color: transparent!important; 
    outline: none;
} 

.button:active,.button:hover,button:active,button:hover { 
    outline: none;
} 

.button[data-v-b857464c]:active:enabled,.button[data-v-b857464c]:hover:enabled,button[data-v-b857464c]:active:enabled,button[data-v-b857464c]:hover:enabled,input[data-v-b857464c]:active:enabled,input[data-v-b857464c]:hover { 
    background: var(--gray-800); 
    outline: none;
} 

nav .menu[data-v-b3d01395]  { 
    display: none; 
    left: 200%; 
    position: absolute;
} 

@media (min-width: 45rem){ 
  nav .menu[data-v-b3d01395]  { 
    display: flex; 
    position: static;
  } 
}     

article[data-v-d392d28c] { 
    background: var(--gray-800); 
    box-shadow: var(--border-box); 
    display: grid; 
    grid-auto-rows: var(--grid-size); 
    grid-template-columns: 1fr; 
    grid-template-rows: repeat(auto-fill,var(--grid-size)); 
    height: calc(var(--grid-size)*10); 
    overflow: hidden; 
    position: relative; 
    width: calc(var(--grid-size)*16);
} 

img { 
    border-style: solid;
} 

img { 
    display: block; 
    vertical-align: middle;
} 

img { 
    height: auto; 
    max-width: 100%;
} 

svg { 
    display: block; 
    vertical-align: middle;
} 

nav .menu-toggle svg[data-v-b3d01395]  { 
    height: 100%; 
    width: 100%;
} 

.button.icon[data-v-b857464c] svg ,button.icon[data-v-b857464c] svg  { 
    max-height: var(--size-5); 
    max-width: var(--size-5); 
    width: 100%;
} 

.button { 
    background: var(--bg); 
    box-shadow: var(--border-box); 
    justify-content: center; 
    outline: none; 
    text-align: center; 
    text-transform: uppercase;
} 

.button { 
    border-color: transparent!important;
} 

.button[data-v-b857464c] { 
    align-items: center; 
    background: var(--bg); 
    box-shadow: var(--border-box); 
    display: flex; 
    height: var(--grid-size); 
    justify-content: center; 
    padding: var(--size-2) var(--size-3); 
    text-align: center; 
    text-transform: uppercase; 
    transition: background var(--speed),color var(--speed),box-shadow var(--speed); 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    user-select: none;
} 

.button:hover:enabled { 
    background: var(--gray-800)!important; 
    border-color: transparent!important; 
    outline: none;
} 

.button:active:enabled,.button:hover:enabled { 
    outline: none;
} 

.button[data-v-b857464c]:active:enabled,.button[data-v-b857464c]:hover:enabled { 
    background: var(--gray-800); 
    outline: none;
} 

.dropdown[data-v-eb88d295] { 
    position: relative;
} 

button[data-v-10cea003] { 
    display: flex;
} 

.button[data-v-0b6ae872],button[data-v-0b6ae872] { 
    align-items: center; 
    background: var(--gray-z-1); 
    border: var(--border); 
    color: var(--gray-z-8); 
    display: flex; 
    gap: var(--size-3); 
    outline: none; 
    padding: calc(var(--size-0)*1.5 + var(--size-1)) var(--size-4) calc(var(--size-0) + var(--size-1)); 
    transition: all var(--speed); 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    user-select: none;
} 

.button[data-v-0b6ae872]:active,.button[data-v-0b6ae872]:hover:enabled,button[data-v-0b6ae872]:active,button[data-v-0b6ae872]:hover:enabled { 
    background-color: var(--gray-z-2); 
    border-color: var(--gray-z-5); 
    color: var(--color); 
    outline: none;
} 

header[data-v-2e84e883] { 
    display: grid!important; 
    grid-template-columns: repeat(8,minmax(0,1fr));
} 

header[data-v-2e84e883] { 
    border-bottom: var(--border); 
    overflow: hidden;
} 

article[data-v-d392d28c] > header  { 
    align-items: center; 
    -webkit-backdrop-filter: var(--bg-blur); 
    backdrop-filter: var(--bg-blur); 
    background: var(--semi); 
    border-bottom: var(--border); 
    display: flex; 
    grid-row: 1/2; 
    height: var(--grid-size); 
    justify-content: space-between; 
    left: 0; 
    overflow: hidden; 
    position: sticky; 
    top: 0; 
    width: 100%; 
    z-index: 10;
} 

main[data-v-2e84e883] { 
    display: grid!important; 
    grid-template-columns: repeat(8,minmax(0,1fr));
} 

main[data-v-2e84e883] { 
    display: relative; 
    grid-row: span 9; 
    grid-template-rows: repeat(9,minmax(0,1fr));
} 

.dropdown[data-v-eb88d295]:hover > * { 
    opacity: 1; 
    pointer-events: all; 
    z-index: 1;
} 

.button.router-link-exact-active[data-v-b857464c] { 
    background: var(--gray-600);
} 

.dropdown[data-v-eb88d295] > :not(:first-child)  { 
    opacity: 0; 
    pointer-events: none; 
    position: absolute;
} 

nav[data-v-b3d01395] .button.router-link-active  { 
    background: var(--gray-600)!important;
} 

.dropdown[data-v-eb88d295] > :not(:first-child):nth-child(2)  { 
    top: 100%;
} 

.dropdown[data-v-eb88d295] > :not(:first-child):nth-child(3)  { 
    top: 200%;
} 

.dropdown[data-v-eb88d295] > :not(:first-child):nth-child(4)  { 
    top: 300%;
} 

.small { 
    font-size: 1rem; 
    font-size: var(--font-size-md);
} 

span[data-v-56eb38e8] { 
    align-items: center; 
    display: inline-flex; 
    gap: var(--size-3); 
    height: 100%;
} 

header > div[data-v-2e84e883]  { 
    display: grid; 
    grid-column: span 2; 
    grid-template-columns: repeat(4,minmax(0,1fr)); 
    height: var(--grid-size);
} 

header > div[data-v-2e84e883]:first-child  { 
    border-right: var(--border);
} 

header > div[data-v-2e84e883]:nth-child(2)  { 
    color: var(--gray-200);
    border-right: var(--border);
} 

header > div[data-v-2e84e883]:nth-child(3)  { 
    color: var(--gray-200);
    border-right: var(--border);
} 

header > div[data-v-2e84e883]:last-child  { 
    color: var(--gray-200);
} 

main .parent[data-v-2e84e883]  { 
    border-bottom: var(--border); 
    grid-column: span 2; 
    grid-row: 1/span 4; 
    overflow: hidden;
} 

main .parent[data-v-2e84e883]:first-child  { 
    border-right: var(--border);
} 

main .parent[data-v-2e84e883]:nth-child(2)  { 
    border-right: var(--border);
} 

main .parent[data-v-2e84e883]:nth-child(3)  { 
    border-right: var(--border);
} 

main .swap[data-v-2e84e883]  { 
    align-items: center; 
    display: flex; 
    justify-content: center; 
    left: calc(50% - var(--size-5)); 
    padding: var(--size-2); 
    position: absolute; 
    top: calc(var(--grid-size)*2.75); 
    width: calc(var(--size-5)*2); 
    z-index: 10;
} 

.button.sm[data-v-b857464c],button.sm[data-v-b857464c] { 
    font-size: var(--font-size-sm); 
    height: auto; 
    padding: var(--size-1) var(--size-3);
} 

.button.rounded[data-v-b857464c],button.rounded[data-v-b857464c] { 
    border-radius: var(--size-5);
} 

.button.rounded.sm[data-v-b857464c],button.rounded.sm[data-v-b857464c] { 
    min-width: 0!important;
} 

main .swap[data-v-2e84e883]:active, main .swap[data-v-2e84e883]:hover { 
    background: var(--gray-600); 
    outline: none;
} 

main .child[data-v-2e84e883]  { 
    border: var(--border); 
    border-top: none; 
    grid-column: 4/6; 
    grid-row: span 4;
} 

main aside.parent-attributes[data-v-2e84e883]  { 
    border-bottom: var(--border); 
    grid-column: 1/3; 
    grid-row: 5/9; 
    text-transform: uppercase;
} 

main aside.parent-attributes[data-v-2e84e883]:last-of-type  { 
    grid-column: 7/-1;
} 

.nowrap { 
    white-space: nowrap;
} 

main .action[data-v-2e84e883]  { 
    border: none; 
    grid-column: 1/-1; 
    grid-row: 9;
} 

.button.light[data-v-b857464c],button.light[data-v-b857464c] { 
    background-color: var(--gray-600);
} 

.button.light[data-v-b857464c]:active:enabled,.button.light[data-v-b857464c]:hover:enabled,button.light[data-v-b857464c]:active:enabled,button.light[data-v-b857464c]:hover:enabled { 
    background: var(--gray-400);
} 

span > span[data-v-56eb38e8]  { 
    align-items: flex-start; 
    display: flex; 
    flex-direction: column; 
    gap: 0; 
    justify-content: center; 
    line-height: 1; 
    text-align: left;
} 

header > div[data-v-2e84e883] .button  { 
    border: none; 
    box-shadow: none; 
    display: flex; 
    grid-column: span 3; 
    justify-content: space-between;
} 

article[data-v-a514b4df] { 
    background: var(--bg); 
    border: 1px solid transparent; 
    position: relative; 
    transition: border var(--speed-fast);
} 

main .swap svg[data-v-2e84e883]  { 
    width: var(--size-4);
} 

div[data-v-8d721357] { 
    background-color: var(--bg); 
    height: 0; 
    overflow: hidden; 
    padding-bottom: 100%; 
    position: relative; 
    width: 100%;
} 

main aside.parent-attributes > div[data-v-2e84e883]  { 
    height: var(--grid-size);
} 

main aside.parent-attributes > div[data-v-2e84e883]:first-child  { 
    align-items: center; 
    
    display: flex; 
    justify-content: center;
} 

.attribute[data-v-021bb64c] { 
    align-items: center; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    text-transform: uppercase;
} 

small { 
    font-size: 80%;
} 

span > span[data-v-56eb38e8] small  { 
    display: block;
} 

svg[data-v-a6425f4c] { 
    background-color: var(--bg); 
    height: calc(var(--grid-size) - 1px); 
    max-width: 100%;
} 

article[data-v-d392d28c] > header svg  { 
    border-left: var(--border); 
    width: var(--grid-size);
} 

header > div span[data-v-2e84e883] svg  { 
    transition: background var(--speed);
} 

svg[data-v-96fbe41a] { 
    background-color: var(--bg); 
    height: calc(var(--grid-size) - 1px); 
    width: 100%;
} 

article .show-dna[data-v-a514b4df]  { 
    bottom: var(--size-3); 
    left: var(--size-3); 
    padding: var(--size-2); 
    position: absolute; 
    transform: translateY(0); 
    transition: all var(--speed-slow); 
    z-index: 19;
} 

article .show-dna[data-v-a514b4df]:active, article .show-dna[data-v-a514b4df]:hover { 
    opacity: 0; 
    transform: translateY(var(--size-2));
} 

article .show-deets[data-v-a514b4df]  { 
    bottom: var(--size-3); 
    right: var(--size-3); 
    padding: var(--size-2); 
    position: absolute; 
    transform: translateY(0); 
    transition: all var(--speed-slow); 
    z-index: 19;
} 

article .show-deets[data-v-a514b4df]:active, article .show-deets[data-v-a514b4df]:hover { 
    opacity: 0; 
    transform: translateY(var(--size-2));
} 

article > aside[data-v-a514b4df]  { 
    -webkit-backdrop-filter: var(--bg-blur); 
    backdrop-filter: var(--bg-blur); 
    background: var(--semi); 
    display: grid; 
    grid-template-columns: repeat(4,minmax(0,1fr)); 
    grid-template-rows: repeat(4,minmax(0,1fr)); 
    height: 100%; 
    left: 0; 
    opacity: 0; 
    pointer-events: none; 
    position: absolute; 
    top: 0; 
    transform: translateY(var(--size-2)); 
    transition: all var(--speed-slow); 
    width: 100%; 
    z-index: 10;
    margin-top: -10px;
} 

.static[data-v-8d721357] { 
    pointer-events: none;
} 

footer[data-v-a514b4df] { 
    background: var(--bg); 
    border-top: var(--border-dark); 
    font-size: var(--font-size-sm); 
    height: var(--size-6); 
    margin-top: auto; 
    padding: var(--size-1) var(--size-3); 
    text-align: center;
} 

article.simple footer[data-v-a514b4df]  { 
    display: none;
} 

article .show-dna[data-v-a514b4df]:active, article .show-dna[data-v-a514b4df]:focus, article .show-dna[data-v-a514b4df]:hover { 
    opacity: 0; 
    transform: translateY(var(--size-2));
} 

.show-dna[data-v-a514b4df]:hover + aside[data-v-a514b4df] {
    opacity: 1;
}

article .show-deets[data-v-a514b4df]:active, article .show-deets[data-v-a514b4df]:focus, article .show-deets[data-v-a514b4df]:hover { 
    opacity: 0; 
    transform: translateY(var(--size-2));
} 

.show-deets[data-v-a514b4df]:hover + aside[data-v-a514b4df] {
    opacity: 1;
}

.attribute span[data-v-021bb64c]:first-child  { 
    color: var(--gray-200); 
    font-size: var(--font-size-sm); 
    margin-bottom: calc(var(--size-1)*-1); 
    margin-top: calc(var(--size-0)*-1);
} 

.flip { 
    transform: scaleX(-1);
} 

article > aside > [data-v-a514b4df]  { 
    grid-column: span 2;
} 

article > aside > [data-v-a514b4de]  { 
    grid-column: span 4;
} 

article > aside[data-v-a514b4df] > :first-child  { 
    align-items: center; 
    display: flex; 
    grid-column: span 4; 
    justify-content: center;
} 

article > aside[data-v-a514b4df] > :not(:first-child)  { 
    border-top: var(--border);
} 

article > aside[data-v-a514b4df] > :nth-child(2n)  { 
    border-right: var(--border);
} 

.next-day[data-v-2e84e883] { 
    display: inline-flex;
} 

.static[data-v-8d721357] g  { 
    pointer-events: all;
} 

.countdown[data-v-10441266] { 
    text-align: center;
} 

.countdown span[data-v-10441266]  { 
    display: block;
} 

.countdown[data-v-10441266] > :first-child  { 
    margin-top: 0;
} 

.countdown[data-v-10441266] > :last-child  { 
    margin-bottom: 0;
} 


/* These were inline style tags. Uses id+class to override almost everything */
#style-Bnr8f.style-Bnr8f {  
   width: 1081px;  
    min-height: 961px;  
    left: 19.5px;  
    top: 33.5px;  
}  
#style-RgKIf.style-RgKIf {  
   min-width: 0px;  
}  
#style-SsynS.style-SsynS {  
   min-width: 120px;  
}  
#style-9rOrT.style-9rOrT {  
   min-width: 120px;  
}  
#style-wbi59.style-wbi59 {  
   min-width: 120px;  
}  
#style-RBez8.style-RBez8 {  
   min-width: 120px;  
}  
#style-O1Poo.style-O1Poo {  
   min-width: 120px;  
}  
#style-TcmPm.style-TcmPm {  
   min-width: 120px;  
}  
#style-oigEr.style-oigEr {  
   min-width: 120px;  
}  
#style-fAK3b.style-fAK3b {  
   height: 600px;  
}  
#style-sj4rx.style-sj4rx {  
   min-width: 180px;  
}  
#style-evf6X.style-evf6X {  
   min-width: 180px;  
}  
#style-pXrCk.style-pXrCk {  
   min-width: 480px;  
}  

.nonClickableButton {
    cursor: default; /* This will ensure the cursor doesn't change to 'pointer' on hover */
    /* Add other styles as needed */
}

.toggle button.active {
    background: var(--gray-600);
}

.toggle button:hover {
    background: var(--gray-600)!important;
}

#copyButton:hover {
    background: white!important;
}

.button.hidden {
    opacity: 0;
    transform: translateY(var(--size-2))!important;
}

button:disabled {
    /* Styles for disabled button */
    cursor: default;
    opacity: 0.5; /* example to show it's disabled */
}

button:disabled:hover {
    background: var(--gray-400)
}